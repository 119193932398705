var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"cloudcc_email"},[_c('h2',[_c('svg',{staticClass:"title_img",attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"href":"#icon-email11"}})]),_c('span',[_vm._v(_vm._s(_vm.$t("label_emailtocloudcc_titel")))])]),_c('div',{staticClass:"email_list_box"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.codeflag),expression:"!codeflag"}],staticClass:"email_list_model"},[_c('h3',[_c('svg',{staticClass:"warning_icon",attrs:{"aria-hidden":"true"}},[_c('use',{attrs:{"href":"#icon-danger"}})]),_c('span',{staticClass:"warning_content"},[_vm._v(_vm._s(_vm.content))])])]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.codeflag),expression:"codeflag"}],staticClass:"email_list"},[(_vm.emailList.length >= 10)?_c('div',{staticClass:"add_btn limit_add_btn",on:{"click":_vm.showLimit}},[_vm._v(" "+_vm._s(_vm.$t("label_emailtocloudcc_button_addemailaddress"))+" ")]):_vm._e(),(_vm.emailList.length < 10)?_c('div',{staticClass:"add_btn"},[_c('div',[_vm._v(_vm._s(_vm.$t("label_right_click_notice")))]),_c('router-link',{attrs:{"to":{
            path: '/add',
            query: { userid: _vm.userid, groupid: _vm.groupid, lange: _vm.lange },
          },"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t("label_emailtocloudcc_button_addemailaddress"))+" ")])],1):_vm._e(),_c('div',{staticClass:"email_list_content"},[_c('table',[_c('thead',[_c('tr',[_c('th',[_vm._v(_vm._s(_vm.$t("label_emailtocloudcc_filed_action")))]),_c('th',[_vm._v(_vm._s(_vm.$t("label_emailtocloudcc_filed_email")))]),_c('th',[_vm._v(_vm._s(_vm.$t("label_emailtocloudcc_filed_type")))]),_c('th',[_vm._v(_vm._s(_vm.$t("label_emailtocloudcc_filed_owner")))])])]),(_vm.emailList[0])?_c('tbody',_vm._l((_vm.emailList),function(item,index){return _c('tr',{directives:[{name:"show",rawName:"v-show",value:(item.cctype == 1 || item.cctype == 2),expression:"item.cctype == 1 || item.cctype == 2"}],key:index},[_c('td',{staticClass:"edit_td"},[_c('router-link',{attrs:{"to":{
                    path: '/edit',
                    query: {
                      userid: _vm.userid,
                      groupid: _vm.groupid,
                      lange: _vm.lange,
                      item: item.id,
                    },
                  },"target":"_blank"}},[_c('span',{staticClass:"edit_btn",on:{"click":function($event){return _vm.editEmail(item)}}},[_vm._v(_vm._s(_vm.$t("label_emailtocloudcc_edit")))])]),_c('span',{staticClass:"line_icon"},[_vm._v("|")]),_c('span',{staticClass:"delete_btn",on:{"click":function($event){return _vm.showModal(item)}}},[_vm._v(_vm._s(_vm.$t("label_emailtocloudcc_delete")))])],1),_c('td',[_vm._v(_vm._s(item.syncmail))]),_c('td',[_vm._v(" "+_vm._s(item.cctype == 1 ? "Email to Case" : "Email to Lead")+" ")]),_c('td',[_vm._v(_vm._s(item.ccname))])])}),0):_vm._e()])]),_c('el-dialog',{attrs:{"title":this.$i18n.t('label_emailtocloudcc_delete'),"visible":_vm.visible,"width":"600px"},on:{"update:visible":function($event){_vm.visible=$event}}},[_c('p',[_vm._v(_vm._s(_vm.$t('label.email.clearAllemail')))]),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":_vm.cancelbutton}},[_vm._v(_vm._s(_vm.$t('label_emailtocloudcc_button_back')))]),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.handleDelete('1')}}},[_vm._v(_vm._s(_vm.$t('label.email.clearCase')))]),_c('el-button',{attrs:{"type":"primary"},on:{"click":function($event){return _vm.handleDelete('0')}}},[_vm._v(_vm._s(_vm.$t('label.email.clearAll')))])],1)]),_c('el-dialog',{attrs:{"title":this.$i18n.t('label_emailsync_message'),"visible":_vm.limitVisible,"width":"440px"},on:{"update:visible":function($event){_vm.limitVisible=$event}}},[_c('p',[_vm._v(_vm._s(_vm.$t("label_mailboxes_limit")))]),_c('div',{attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{on:{"click":_vm.cancelbutton}},[_vm._v(_vm._s(_vm.$t('label_emailtocloudcc_button_back')))]),_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.handleLimit}},[_vm._v(_vm._s(_vm.$t('label_emailtocloudcc_button_sure')))])],1)])],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }