
<template>
   <div class="cloudcc_email">
    <h2>
      <svg class="title_img" aria-hidden="true">
                <use href="#icon-email11"></use>
              </svg>
      <span>{{ $t("label_emailtocloudcc_titel") }}</span>
    </h2>
    <div class="email_list_box">
    <!-- 警告提醒 -->
    <div class="email_list_model" v-show="!codeflag">
      <h3>
        <svg class="warning_icon" aria-hidden="true">
                <use href="#icon-danger"></use>
              </svg>
        <span class="warning_content">{{ content }}</span>
      </h3>
    </div>
    <!-- 显示列表 -->
    <div class="email_list" v-show="codeflag">
      <!-- loading -->
        <div
          class="add_btn limit_add_btn"
          @click="showLimit"
          v-if="emailList.length >= 10"
        >
          {{ $t("label_emailtocloudcc_button_addemailaddress") }}
        </div>
        <div class="add_btn" v-if="emailList.length < 10">
          <!-- 注意：Email to CloudCC 邮箱不能为系统用户绑定的帐号邮箱，否则将导致无效邮件生成潜在客户或者个案。 -->
          <div>{{ $t("label_right_click_notice") }}</div>
          <router-link
            :to="{
              path: '/add',
              query: { userid: userid, groupid: groupid, lange: lange },
            }"
            target="_blank"
          >
            {{ $t("label_emailtocloudcc_button_addemailaddress") }}
          </router-link>
        </div>
        <!-- 邮件列表 -->
        <div class="email_list_content">
          <table>
            <thead>
              <tr>
                <th>{{ $t("label_emailtocloudcc_filed_action") }}</th>
                <th>{{ $t("label_emailtocloudcc_filed_email") }}</th>
                <th>{{ $t("label_emailtocloudcc_filed_type") }}</th>
                <th>{{ $t("label_emailtocloudcc_filed_owner") }}</th>
              </tr>
            </thead>
            <tbody v-if="emailList[0]">
              <tr
                v-for="(item, index) in emailList"
                :key="index"
                v-show="item.cctype == 1 || item.cctype == 2"
              >
                <td class="edit_td">
                  <router-link
                    :to="{
                      path: '/edit',
                      query: {
                        userid: userid,
                        groupid: groupid,
                        lange: lange,
                        item: item.id,
                      },
                    }"
                    target="_blank"
                  >
                    <span class="edit_btn" @click="editEmail(item)">{{
                      $t("label_emailtocloudcc_edit")
                    }}</span>
                  </router-link>
                  <span class="line_icon">|</span>
                  <span class="delete_btn" @click="showModal(item)">{{
                    $t("label_emailtocloudcc_delete")
                  }}</span>
                </td>
                <td>{{ item.syncmail }}</td>
                <td>
                  {{ item.cctype == 1 ? "Email to Case" : "Email to Lead" }}
                </td>
                <td>{{ item.ccname }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      
      <!-- 删除对话框 -->
      <el-dialog :title="this.$i18n.t('label_emailtocloudcc_delete')" :visible.sync="visible" width="600px">
         <!-- 删除后将无法通过此邮箱实现Email to CloudCC功能，确认删除 -->
        <!-- <p>{{ $t("label_emailtocloudcc_tip2") }}</p> -->
        <!-- 是否清空所有该邮箱同步的邮件 -->
        <p>{{$t('label.email.clearAllemail')}}</p>
        <!-- 自定义底部按钮 -->
          <div slot="footer" >
      <!-- 取消关联 “取消”按钮 -->
            <el-button  @click="cancelbutton">{{$t('label_emailtocloudcc_button_back')}}</el-button>
            <!-- 只清空未关联个案的邮件 -->
            <el-button type="primary" @click="handleDelete('1')">{{$t('label.email.clearCase')}}</el-button>
            <!-- 清空所有 -->
            <el-button type="primary" @click="handleDelete('0')">{{$t('label.email.clearAll')}}</el-button>
            <!-- 确认 -->
            <!-- <el-button type="primary" @click="handleDelete">{{$t('label.weixin.confirm')}}</el-button> -->
           </div>
       
        </el-dialog>
      <!-- 邮箱数量超过上限 -->
      <el-dialog
        :title="this.$i18n.t('label_emailsync_message')"
        :visible.sync="limitVisible"
        width="440px"
      >
        <p>{{ $t("label_mailboxes_limit") }}</p>
         <div slot="footer" >
      <!-- 取消关联 “取消”按钮 -->
            <el-button  @click="cancelbutton">{{$t('label_emailtocloudcc_button_back')}}</el-button>
            <el-button type="primary" @click="handleLimit">{{$t('label_emailtocloudcc_button_sure')}}</el-button>
           </div>
      </el-dialog>
    </div>
  </div>
      </div>
</template>
<script>
import fetchData from "@/config/fetch";
import {deleteEmail} from './api.js';
function getUrl(name) {
  var reg = new RegExp(name + "=([^&]*)(&|$)");
  var r = window.location.hash.substr(2).match(reg);
  if (r != null) return unescape(r[1]);
  return null;
}
export default {
  name: "EmailList",
  data() {
    return {
      token:'', // token
      returnurl: window.Glod.EMAIL_RETURN, // 返回路由
      lange: '', // 多语言字段
      groupid: '', // 组织id
      userid:'', // 用户id
      country:'china', // 国家
      visible: false, // 模态框
      limitVisible: false, // 邮箱已达到上限模态框
      emailList: [], // 邮箱列表
      // emailId: "", // 删除同步邮箱id
      emailItem: "", // 删除同步邮箱
      codeflag: false, // 是否显示
      content: "", // code不为200时展示内容
    };
  },
   created(){
    this.userid=localStorage.getItem('userId')
    this.groupid=localStorage.getItem('orgId')
    // 多语言
    this.lange=localStorage.getItem('locale')
    // this.returnurl=this.$route.query.rtnURL
    this.itemId=this.$route.query.item
     // 获取token
      let code = getUrl("code"); // 获取url上状态值code
    // 根据code值显示相应内容
    if (code == 200 || !code) {
      this.codeflag = true;
      fetchData(
        window.Glod['ccex-emailsync']+"/jwtgettoken",
        { userid:  this.userid, groupid: this.groupid },
        function (res) {
          let data = eval(res);
          this.token = data.token;
          // 获取同步邮箱列表
          this.getamilboxlist()
        }.bind(this)
      );
     
    } else {
      if (code == 100) {
        this.content = this.$i18n.t("label_emailtocloudcc_redmessage5");
      } else if (code == 455 || code == 456) {
        this.content = this.$i18n.t("label_emailtocloudcc_redmessage6");
      } else if (code == 401) {
        this.content = this.$i18n.t("label_emailtocloudcc_redmessage7");
      } else if (code == 402) {
        this.content = this.$i18n.t("label_emailtocloudcc_redmessage8");
      } else if (code == 403) {
        this.content = this.$i18n.t("label_emailtocloudcc_redmessage9");
      } else if (code == 404) {
        this.content = this.$i18n.t("label_emailtocloudcc_redmessage10");
      } else if (code == 405) {
        this.content = this.$i18n.t("label_emailtocloudcc_exists");
      } else if (code == 406) {
        this.content = this.$i18n.t("label_mailboxes_limit");
      }
      this.codeflag = false;
    }
  },
  methods: {
     // 获取同步邮箱列表
     getamilboxlist(){
        fetchData(
        window.Glod['ccex-emailsync']+"/jwtgetsyncmails",
        { token: this.token, cctype: "group" },
        function (res) {
          let data = eval(res);
          // 
          if (data.code == 200) {
            this.emailList = data.jwtsyncmails;
          }
        }.bind(this)
      );
     },

    // 编辑同步邮件
    editEmail() {
    },
    // 模态框
    showModal(item) {
      this.visible = true;
      this.emailItem = item;
    },
    // 改变邮箱已达到上限模态框是否展示
    showLimit() {
      this.limitVisible = true;
    },
     //   取消删除同步邮件
      cancelbutton(){
          this.visible=false
      },
    // 删除同步邮件
    handleDelete(val) {
      // 
      let dataDel={
            relateEmail:this.emailItem.syncmail,
            deleteType:val
          }
      // 删除邮箱
      fetchData(
        window.Glod['ccex-emailsync']+"/jwtdelsyncmail",
        { token: this.token, id: this.emailItem.id, cctype: "group" },
        function (res) {
          let data = eval(res);
          if (data.code == 200) {
            //  清空邮件
              //删除类型--0：清空所有  1：只清空未关联相关项的邮件 
              deleteEmail(dataDel)
            fetchData(
              window.Glod['ccex-emailsync']+"/jwtgetsyncmails",
              { token: this.token, cctype: "group" },
              function (res) {
                let data = eval(res);
                // 
                this.emailList = data.jwtsyncmails;
                this.visible = false;
              }.bind(this)
            );
          }
        }.bind(this)
      );
      this.visible=false
    },
    //  邮箱已达上限
    handleLimit() {
      this.limitVisible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.cloudcc_email {
  height: 100%;
  width: 100%;
  // background: #f2f2f5;
  padding: 18px 10px 0 10px;
  h2 {
    font-size: 20px;
    color: #333333;
    letter-spacing: 0;
    display: flex;
    align-items: center;
    span {
      font-size: 20px;
      color: #333333;
      letter-spacing: 0;
      font-family: Helvetica;
    }
    .title_img {
      width: 35px;
      height: 35px;
      margin-right: 20px;
    }
  }
}
body {
  position: relative;
}
.email_list_model {
  margin-top: 120px;
  display: flex;
  align-items: center;
}
.email_list_model h3 {
  width: 100%;
 display: flex;
 justify-content: center;
 align-items: center;
}
.email_list_model h3 .warning_icon {
  display: inline-block;
  width: 48px;
  height: 48px;
  margin-right: 20px;
  color: #66b1ff;
  font-size: 48px;
}
.email_list_model h3 .warning_content {
  display: inline-block;
  font-size: 40px;
  color: #66b1ff;
}
.email_list {
  background: #ffffff;
  border: 1px solid #d8dde6;
  border-radius: 4px;
  margin-top: 16px;
  padding: 15px 20px 100px;
}
.email_list .add_btn {
  height: 30px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.email_list .add_btn a {
  float: right;
  height: 30px;
  /* width: 105px; */
  background: #66b1ff;
  border-radius: 3px;
  font-size: 14px;
  color: #ffffff;
  letter-spacing: 0;
  line-height: 30px;
  text-align: center;
  font-family: "雅黑";
  padding: 0 15px;
}
.email_list .limit_add_btn {
  float: right;
  height: 30px;
  /* width: 105px; */
  background: #66b1ff;
  border-radius: 3px;
  font-size: 14px;
  color: #ffffff;
  letter-spacing: 0;
  line-height: 30px;
  text-align: center;
  font-family: "雅黑";
  padding: 0 15px;
  margin-bottom: 15px;
}
/* .email_list .email_list_content{
		margin-top: 15px;
	} */
.email_list table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;
}
.email_list table tr {
  height: 37px;
  border-top: 1px solid #dcdcdc;
}
.email_list table tr:last-child {
  border-bottom: 1px solid #dcdcdc;
}
.email_list table tr th {
  width: 25%;
  padding: 0 20px;
  text-align: left;
  font-family: MicrosoftYaHei;
  font-size: 12px;
  color: #333333;
  letter-spacing: 0;
  line-height: 36px;
}
.email_list table tr th::before {
  display: inline-block;
  content: "";
  height: 12px;
  width: 1px;
  background: #dcdcdc;
  margin-right: 10px;
}
.email_list table tr td {
  padding: 0 30px;
  font-family: MicrosoftYaHei;
  font-size: 12px;
  color: #333333;
  letter-spacing: 0;
  line-height: 36px;
}
.email_list table tr .edit_td .line_icon {
  margin: 0 3px;
  vertical-align: top;
  height: 20px;
}
.email_list table tr .edit_td .edit_btn {
  color: #333333;
  /* color: #015ba7; */
}
.email_list table tr .edit_td .edit_btn:hover {
  cursor: pointer;
  text-decoration: underline;
}
.email_list table tr .edit_td .delete_btn:hover {
  cursor: pointer;
}
.email_list table tr td:nth-child(2) {
  color: #015ba7;
}
.modal {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  z-index: 99;
}
.modal .modal_box {
  height: 187px;
  width: 440px;
  background: #ffffff;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  margin: 150px auto;
  padding: 20px;
  box-sizing: border-box;
}
.modal .modal_box .modal_title {
  padding-bottom: 10px;
  border-bottom: 1px solid #dcdcdc;
  display: flex;
  justify-content: space-between;
}
.modal .modal_box .modal_title h4 {
  font-family: MicrosoftYaHei;
  font-size: 14px;
  color: #333333;
  letter-spacing: 0;
}
.modal .modal_box .modal_detail {
  text-align: center;
}
.modal .modal_box .modal_detail p {
  font-family: MicrosoftYaHei;
  font-size: 12px;
  color: #777777;
  letter-spacing: 0;
  margin-top: 20px;
  text-align: left;
}
.modal .confirm_btn {
  background: #66b1ff;
  border: 1px solid #66b1ff;
  border-radius: 4px;
  padding: 8px 20px;
  box-sizing: border-box;
  font-family: MicrosoftYaHei;
  font-size: 12px;
  color: #ffffff;
  letter-spacing: 0;
  text-align: center;
  margin: 36px auto;
}
</style>